import { Component } from '@angular/core';
import { Rank } from './rank';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  n = 1;
  prix = 0;
  wwf = 0;
  ranking: Rank[] = [];
  error = false;

  annee = new Date().getFullYear();

  constructor() {
    this.ranking = [
      {rank: 1, pseudo: 'bibol32', price: 1334.64},
      {rank: 2, pseudo: 'buggy', price: 824.57},
      {rank: 3, pseudo: '_tzar_', price: 751.12},
      {rank: 4, pseudo: 'anakin.75', price: 512.74},
      {rank: 5, pseudo: 'AleX', price: 249.98},
      {rank: 6, pseudo: 'angel1300', price: 248.45},
      {rank: 7, pseudo: 'benj.aze', price: 140.91},
      {rank: 8, pseudo: 'Stef_KZ', price: 124.49},
      {rank: 9, pseudo: 'juju123', price: 111.44},
      {rank: 10, pseudo: 'sunnyboy', price: 105.81},
      {rank: 11, pseudo: 'aster0id', price: 99.10},
      {rank: 12, pseudo: 'coccy', price: 85.74}
    ];
  }

  setPrix(prix: number) {
    this.prix = prix;
    this.wwf = 0.9 * prix;
  }

  regenerate() {
    this.n += 1;
    this.error = false;
  }

  buy() {
    this.error = true;
  }
}
