import { Abdomen } from './abdomen';
import { Tete } from './tete';
import { Thorax } from './thorax';

export class Insecte {
    tete: Tete;
    thorax: Thorax;
    abdomen: Abdomen;
    rarete: number;

    constructor() {
        this.tete = new Tete();
        this.thorax = new Thorax();
        this.abdomen = new Abdomen();
        this.rarete = 1;
    }
}

