export class Abdomen {
  dy: number;
  path: string;
  couleur: string;
  reflet: string;
  carapace: boolean;
  dard: Dard;
  motif: Motif;
}

export class Dard {
  path: string;
}

export class Motif {
  paths: string[];
  couleur: string;

  constructor() {
    this.paths = [];
  }
}
