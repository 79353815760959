export class Tete {
  antennes: Antennes;
  yeux: Yeux;
  ocelles: Ocelles;
  mandibules: Mandibules;
  broyeurs: Broyeurs;
  trompe: Trompe;

  dy: number;
  path: string;
  couleur: string;
  reflet: string;
}

export class Antennes {
  pathGauche: string;
  pathDroite: string;
  epaisseur: number;
}

export class Yeux {
  cx: number;
  cy: number;
  rx: number;
  ry: number;
  couleur: string;
}

export class Ocelles {
  r: number;
  yAvant: number;
  yArriere: number;
  xArriere: number;
  couleur: string;
}

export class Mandibules {
  pathGauche: string;
  pathDroite: string;
  epaisseur: number;
}

export class Broyeurs {
  pathGauche: string;
  pathDroite: string;
}

export class Trompe {
  epaisseur: number;
  y: number;
}
