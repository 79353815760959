export class Thorax {
    ailes: Ailes;
    pattes: Pattes;

    dy: number;
    path: string;
    couleur: string;
    reflet: string;
}

export class Ailes {
  paths: string[];
}

export class Pattes {
  paths: string[];
  epaisseur: number;
}
