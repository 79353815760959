<div class="page">
  <!--<h1>Randbug <span class="beta">beta</span></h1>-->
  <div class="insecte">
    <app-insecte [regenerate]="n" (click)="regenerate()" (score)="setPrix($event)"></app-insecte>
  </div>
  <!--<div class="actions">
    <div class="button" (click)="buy()">
      <span class="label">Buy</span>{{prix | number:'0.2-2'}} €
    </div>
    <div class="button" (click)="regenerate()">
      <span class="label">Next</span>
      <span class="material-icons">refresh</span>
    </div>
  </div>
  <div *ngIf="error" class="error">Sorry, you cannot buy this insect for now.</div>
  <div class="explications">
    {{wwf | number:'0.2-2'}} €
    will be donated to <br />
    <img class="wwf-logo"
      src="https://upload.wikimedia.org/wikipedia/fr/thumb/7/79/WWF_logo_2000.svg/1200px-WWF_logo_2000.svg.png"
      alt="wwf" />
  </div>
  <div class="ranking">
    <h2>Ranking</h2>
    <div *ngFor="let rank of ranking" class="rank">
      <app-insecte></app-insecte>
      <div class="details">#{{rank.rank}} - {{rank.pseudo}} - {{rank.price | number:'0.2-2'}} €</div>
    </div>
  </div>-->
  <footer>
    <div>Score de rareté : {{prix}}</div>
    <div>Ce site est hébergé chez OVH<br />2 rue Kellermann 59100 Roubaix FRANCE</div>
    <div>Clément Michard &copy; {{annee}}</div>
  </footer>
</div>
