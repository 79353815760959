import { Insecte } from './anatomie/insecte';
import { Dard, Motif } from './anatomie/abdomen';
import { Pattes, Ailes } from './anatomie/thorax';
import { Yeux, Antennes, Mandibules, Broyeurs, Trompe, Ocelles } from './anatomie/tete';
import { v4 as uuidv4 } from 'uuid';


import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-insecte',
  templateUrl: './insecte.component.html',
  styleUrls: ['./insecte.component.scss']
})
export class InsecteComponent {
  @Input() set regenerate(name: string) {
    this.generate();
  }
  @Output() score = new EventEmitter<number>();

  i: Insecte;
  id: string;

  searchRarete = 0;
  strokeWidth = 0.1;

  minDyTete = 2;
  maxDyTete = 10;
  minDyThorax = 2;
  maxDyThorax = 10;
  maxDyAbdomen = 30;

  maxDxCtrl = 70;
  minDxCtrl = 10;



  haut: number = null;
  cou: number = null;
  hanche: number = null;
  bas: number = null;

  generated = false;

  constructor() {
    this.generate();
  }

  generate() {
    while (true) {
      console.clear();
      this.i = new Insecte();
      this.setProportions();
      this.setTete();
      this.setThorax();
      this.setAbdomen();
      this.setOcelles(this.i.abdomen.carapace || this.i.thorax.ailes != null);
      this.generated = true;
      const scoreRarete = this.getRareteScore();
      if (scoreRarete > this.searchRarete) {
        this.id = uuidv4();
        this.score.emit(Math.round(scoreRarete * 100) / 100);
        return;
      }
    }
  }

  getId(name: string): string {
    return name + this.id;
  }

  getUrl(name: string): string {
    return `url(#${name}${this.id})`;
  }

  getRareteScore() {
    return 1 / (this.i.rarete * 1000);
  }

  setProportions() {
    this.i.tete.dy = this.random(this.minDyTete, this.maxDyTete);
    this.i.thorax.dy = this.random(this.minDyThorax, this.maxDyThorax);
    this.i.abdomen.dy = this.random(Math.max(this.i.tete.dy, this.i.thorax.dy), this.maxDyAbdomen);

    this.cou = this.i.thorax.dy / 2;
    this.haut = this.cou + this.i.tete.dy;
    this.hanche = -this.cou;
    this.bas = this.hanche - this.i.abdomen.dy;

    if (this.i.abdomen.dy > 2 * this.i.tete.dy + this.i.thorax.dy) {
      this.setProportions();
      return;
    }
  }
  setRarete(valeur: number, caracteristique: string) {
    this.i.rarete *= valeur;
    if (caracteristique != null) {
      console.log(caracteristique);
    }
  }

  setTete() {
    const ctrlHaut = this.randomPct(this.minDxCtrl, this.maxDxCtrl) * this.i.tete.dy;
    const ctrlCou = this.randomPct(this.minDxCtrl, this.maxDxCtrl) * this.i.tete.dy;
    this.i.tete.path = `M 0 ${this.haut} `
      + `C -${ctrlHaut} ${this.haut}, -${ctrlCou} ${this.cou}, 0 ${this.cou} `
      + `C ${ctrlCou} ${this.cou}, ${ctrlHaut} ${this.haut}, 0 ${this.haut}`;
    if (ctrlHaut + ctrlCou < this.i.tete.dy / 2) {
      this.setTete();
      return;
    }
    this.setYeux(ctrlHaut);
    this.setAntennes();
    this.setCouleurs();

    const appendice = Math.random();
    if (appendice < 0.3) {
      this.setRarete(0.3, 'Broyeurs');
      this.setBroyeurs();
    } else if (appendice < 0.5) {
      this.setRarete(0.2, 'Trompe');
      this.setTrompe();
    } else {
      this.setRarete(0.5, null);
    }
    if (Math.random() < 0.4) {
      this.setRarete(0.4, 'Mandibules');
      this.setMandibules();
    } else {
      this.setRarete(0.6, null);
    }

    if (Math.random() < 0.1) {
      this.i.tete.reflet = this.randomFlashyColor();
      this.setRarete(0.1, 'Fluorescence');
    } else {
      this.i.tete.reflet = 'white';
      this.setRarete(0.9, null);
    }
  }

  setOcelles(trois: boolean) {
    const ocelles = new Ocelles();
    ocelles.xArriere = (this.i.tete.yeux.cx - this.i.tete.yeux.rx) / 3;
    if (trois) {
      ocelles.yAvant = this.i.tete.yeux.cy + this.i.tete.yeux.ry / 3;
      ocelles.yArriere = this.i.tete.yeux.cy - this.i.tete.yeux.ry / 3;
    } else {
      ocelles.yArriere = this.i.tete.yeux.cy;
    }
    ocelles.r = Math.min(ocelles.xArriere, this.randomPct(10, 30) * this.i.tete.yeux.rx);
    ocelles.couleur = this.randomBasicColor();
    this.i.tete.ocelles = ocelles;
  }

  setMandibules() {
    const mandibules = new Mandibules();
    const taille = (this.i.tete.dy * this.randomPct(25, 100));
    const base = this.haut - taille / 3;
    const extremite = [taille / 3, this.haut + taille * this.randomPct(50, 100)];
    const articulation = [taille / this.random(2, 3), this.haut + taille / 3];
    mandibules.pathGauche = `M 0 ${base} L ${articulation[0]} ${articulation[1]} L ${extremite[0]} ${extremite[1]}`;
    mandibules.pathDroite = `M 0 ${base} L ${-articulation[0]} ${articulation[1]} L ${-extremite[0]} ${extremite[1]}`;
    mandibules.epaisseur = this.randomPct(30, 60);
    this.i.tete.mandibules = mandibules;
  }

  setTrompe() {
    const trompe = new Trompe();
    const taille = (this.i.tete.dy * this.randomPct(10, 100));
    trompe.y = this.haut + taille;
    trompe.epaisseur = this.randomPct(30, 60);
    this.i.tete.trompe = trompe;
  }

  setBroyeurs() {
    const broyeurs = new Broyeurs();
    const taille = (this.i.tete.dy * this.randomPct(25, 75));
    const base = this.haut - taille / 3;
    const extremite = [taille / 3, this.haut + taille / 2];
    const ctrl = [0.75 * taille, this.haut];
    broyeurs.pathGauche = `M 0 ${base} Q ${ctrl[0]} ${ctrl[1]}, ${extremite[0]} ${extremite[1]} Z`;
    broyeurs.pathDroite = `M 0 ${base} Q ${-ctrl[0]} ${ctrl[1]}, ${-extremite[0]} ${extremite[1]} Z`;
    this.i.tete.broyeurs = broyeurs;
  }

  setYeux(ctrlHaut: number) {
    const yeux = new Yeux();
    yeux.cx = this.randomPct(25, 70) * ctrlHaut;
    yeux.cy = this.haut - 0.3 * this.i.tete.dy;
    yeux.rx = Math.min(yeux.cx, this.randomPct(25, 75) * ctrlHaut / 2);
    yeux.ry = Math.max(yeux.rx, this.randomPct(25, 100) * ctrlHaut / 2);
    yeux.couleur = this.randomBasicColor();
    this.i.tete.yeux = yeux;
  }

  setAntennes() {
    const antennes = new Antennes();
    const base = this.cou + this.randomPct(50, 100) * this.i.tete.dy;
    const dx = this.randomPct(20, 50) * (this.haut - this.bas);
    const dy = this.randomPct(20, 50) * (this.haut - this.bas);
    const x = dx;
    const y = base + dy;
    const xCtrl = (dx / 2) + (this.random(0, 10) - 5);
    const yCtrl = base + dy / 2;
    antennes.pathDroite = `M 0 ${base} C 0 ${base}, ${xCtrl} ${yCtrl}, ${x} ${y}`;
    antennes.pathGauche = `M 0 ${base} C 0 ${base}, ${-xCtrl} ${yCtrl}, ${-x} ${y}`;
    antennes.epaisseur = this.randomPct(30, 60);
    this.i.tete.antennes = antennes;
  }

  setThorax() {
    const ctrlCou = this.randomPct(this.minDxCtrl, this.maxDxCtrl) * this.i.thorax.dy;
    const ctrlHanche = this.randomPct(this.minDxCtrl, this.maxDxCtrl) * this.i.thorax.dy;
    const colleHanche = this.random(0, 2);
    const colleCou = this.random(0, 2);
    this.i.thorax.path = `M 0 ${this.cou} `
      + `C -${ctrlCou} ${this.cou + colleCou}, -${ctrlHanche} ${this.hanche - colleHanche}, 0 ${this.hanche} `
      + `C ${ctrlHanche} ${this.hanche - colleHanche}, ${ctrlCou} ${this.cou + colleCou}, 0 ${this.cou}`;
    if (ctrlCou + ctrlHanche < this.i.thorax.dy / 2) {
      this.setThorax();
      return;
    }
    this.setPattes();
    if (Math.random() < 0.1) {
      this.i.thorax.reflet = this.randomFlashyColor();
      this.setRarete(0.1, 'Thorax trés coloré');
    } else {
      this.i.thorax.reflet = 'white';
      this.setRarete(0.9, null);
    }
  }

  setPattes() {
    const t = this.random(1, this.haut - this.bas) / 7;
    const p1 = [this.random(t, 2 * t), this.random(0.5 * t, 2 * t)];
    const p2 = [this.random(0, t), this.random(t, 2 * t)];
    const p3 = [this.random(t, 2 * t), this.random(0, 2 * t) - 0.5 * t];

    const p4 = [this.random(t, 2 * t), this.random(0, 0.5 * t)];
    const p5 = [this.random(0.5 * t, 2 * t), this.random(-0.5 * t, -1.5 * t)];
    const p6 = [this.random(t, 2 * t), this.random(0, -2 * t) + 0.5 * t];

    const p7 = [this.random(1.5 * t, 2.5 * t), this.random(-1 * t, -2.5 * t)];
    const p8 = [this.random(0, 1.5 * t), this.random(-1.5 * t, -2.5 * t)];
    const p9 = [this.random(1.5 * t, 2.5 * t), this.random(0, -2.5 * t) + 0.5 * t];

    this.i.thorax.pattes = new Pattes();
    const pattes: string[] = [];
    pattes.push(`M 0 0 l ${p1[0]} ${p1[1]} l ${p2[0]} ${p2[1]} l ${p3[0]} ${p3[1]}`);
    pattes.push(`M 0 0 l ${-p1[0]} ${p1[1]} l ${-p2[0]} ${p2[1]} l ${-p3[0]} ${p3[1]}`);
    pattes.push(`M 0 0 L ${p4[0]} ${p4[1]} l ${p5[0]} ${p5[1]} l ${p6[0]} ${p6[1]}`);
    pattes.push(`M 0 0 L ${-p4[0]} ${p4[1]} l ${-p5[0]} ${p5[1]} l ${-p6[0]} ${p6[1]}`);
    pattes.push(`M 0 0 L ${p7[0]} ${p7[1]} l ${p8[0]} ${p8[1]} l ${p9[0]} ${p9[1]}`);
    pattes.push(`M 0 0 L ${-p7[0]} ${p7[1]} l ${-p8[0]} ${p8[1]} l ${-p9[0]} ${p9[1]}`);
    this.i.thorax.pattes.paths = pattes;
    this.i.thorax.pattes.epaisseur = this.randomPct(30, 60);
  }

  setAbdomen() {
    const ctrlHanche = this.randomPct(50, this.maxDxCtrl) * this.i.abdomen.dy;
    const ctrlBas = this.randomPct(0, this.maxDxCtrl) * this.i.abdomen.dy;
    let colleBas = 0;
    if (Math.random() < 0.5 && this.i.abdomen.dy > this.i.tete.dy + this.i.thorax.dy) {
      colleBas = this.random(0, 2);
    }
    this.i.abdomen.path = `M 0 ${this.hanche} `
      + `C -${ctrlHanche} ${this.hanche}, -${ctrlBas} ${this.bas - colleBas}, 0 ${this.bas} `
      + `C ${ctrlBas} ${this.bas - colleBas}, ${ctrlHanche} ${this.hanche}, 0 ${this.hanche}`;
    if (ctrlHanche + ctrlBas < this.i.abdomen.dy / 3) {
      this.setAbdomen();
      return;
    }
    this.i.abdomen.carapace = colleBas > 0;
    if (!this.i.abdomen.carapace && Math.random() < 0.3) {
      this.setRarete(0.1, 'Ailes');
      this.setAiles();
    } else {
      this.setRarete(0.9, null);
    }
    if (Math.random() < 0.1) {
      this.setRarete(0.1, 'Dard');
      this.setDard();
    } else {
      this.setRarete(0.9, null);
    }
    if (Math.random() < 0.1) {
      this.i.abdomen.reflet = this.randomFlashyColor();
      this.setRarete(0.1, 'Abdomen coloré');
    } else {
      this.i.abdomen.reflet = 'white';
      this.setRarete(0.9, null);
    }
    this.setMotif();
  }

  setMotif() {
    if (Math.random() < 0.3) {
      this.setRarete(0.3, 'Motif');
      const motif = new Motif();
      if (Math.random() < 0.7) {
        this.setRarete(0.7, null);
        motif.couleur = this.randomBasicColor();
      } else {
        this.setRarete(0.3, 'Motifs colorés');
        motif.couleur = this.randomFlashyColor();
      }
      const nbMotif = Math.round(this.random(3, 7));
      const pas = this.i.abdomen.dy / nbMotif;
      const t = this.i.abdomen.dy;
      const controles = [];
      const points = [];
      for (let p = 0; p < this.random(2, 7); p++) {
        controles.push([this.random(0, t / 2), this.random(0, 2 * pas) - pas]);
        points.push([this.random(0, t / 2), this.random(0, 2 * pas) - pas]);
      }
      for (let m = 0; m < nbMotif; m++) {
        const base = this.hanche - m * (pas + 1);
        let pathGauche = `M 0 ${base} `;
        let pathDroite = `M 0 ${base} `;
        // tslint:disable-next-line: prefer-for-of
        for (let p = 0; p < points.length; p++) {
          pathGauche += `Q ${-controles[p][0]} ${controles[p][1] + base} ${-points[p][0]} ${points[p][1] + base} `;
          pathDroite += `Q ${controles[p][0]} ${controles[p][1] + base} ${points[p][0]} ${points[p][1] + base} `;
        }
        pathGauche += 'Z';
        pathDroite += 'Z';
        motif.paths.push(pathDroite);
        motif.paths.push(pathGauche);
      }
      this.i.abdomen.motif = motif;
    } else {
      this.setRarete(0.7, null);
    }
  }

  setDard() {
    const dard = new Dard();
    const bas = [0, this.bas - this.randomPct(25, 50) * this.i.tete.dy];
    const haut = [0.1, this.bas + 1];
    dard.path = `M ${haut[0]} ${haut[1]} L ${bas[0]} ${bas[1]} L ${-haut[0]} ${haut[1]}`;
    this.i.abdomen.dard = dard;
  }

  setAiles() {
    const ailes = new Ailes();
    ailes.paths = [];
    const t = -this.bas;
    let extremite = [this.random(0.5 * t, t), this.random(-0.75 * t, -1.25 * t)];
    let ctrlHaut = [extremite[0] + this.random(2, 10), extremite[1] + this.random(2, 10)];
    let ctrlBas = [extremite[0] - this.random(2, 10), extremite[1] - this.random(2, 10)];
    ailes.paths.push(`M 0 0 `
      + `Q ${ctrlHaut[0]} ${ctrlHaut[1]}, ${extremite[0]} ${extremite[1]} `
      + `Q ${ctrlBas[0]} ${ctrlBas[1]}, 0 -1 L 0 0`
    );
    ailes.paths.push(`M 0 0 `
      + `Q ${-ctrlHaut[0]} ${ctrlHaut[1]}, ${-extremite[0]} ${extremite[1]} `
      + `Q ${-ctrlBas[0]} ${ctrlBas[1]}, 0 -1 L 0 0`
    );

    if (Math.random() < 0.3) {
      this.setRarete(0.3, 'Doubles ailes');
      extremite = [this.random(0.75 * t, 1.25 * t), this.random(-0.75 * t, -1.25 * t)];
      ctrlHaut = [extremite[0] + this.random(2, 10), extremite[1] + this.random(2, 10)];
      ctrlBas = [extremite[0] - this.random(2, 10), extremite[1] - this.random(2, 10)];
      ailes.paths.push(`M 0 0 `
        + `Q ${ctrlHaut[0]} ${ctrlHaut[1]}, ${extremite[0]} ${extremite[1]} `
        + `Q ${ctrlBas[0]} ${ctrlBas[1]}, 0 -1 L 0 0`
      );
      ailes.paths.push(`M 0 0 `
        + `Q ${-ctrlHaut[0]} ${ctrlHaut[1]}, ${-extremite[0]} ${extremite[1]} `
        + `Q ${-ctrlBas[0]} ${ctrlBas[1]}, 0 -1 L 0 0`
      );
    } else {
      this.setRarete(0.7, null);
    }

    this.i.thorax.ailes = ailes;
  }

  setCouleurs() {
    const basic = this.randomBasicColor();
    if (Math.random() < 0.3) {
      this.i.tete.couleur = this.randomColor();
      this.setRarete(0.3, 'Tête colorée');
    } else {
      this.i.tete.couleur = basic;
      this.setRarete(0.7, null);
    }
    if (Math.random() < 0.3) {
      this.i.thorax.couleur = this.randomColor();
      this.setRarete(0.3, 'Thorax coloré');
    } else {
      this.i.thorax.couleur = basic;
      this.setRarete(0.7, null);
    }
    if (Math.random() < 0.7) {
      this.i.abdomen.couleur = this.randomColor();
      this.setRarete(0.7, null);
    } else {
      this.i.abdomen.couleur = basic;
      this.setRarete(0.3, null);
    }
  }

  randomBasicColor(): string {
    const tirage = Math.random();
    let couleur = null;
    if (tirage < 0.6) {
      couleur = 'rgb(0,0,0)';
    } else {
      if (tirage < 0.8) {
        couleur = 'rgb(0,50,0)';
      } else {
        couleur = 'rgb(50,0,0)';
      }
    }
    return couleur;
  }

  randomColor(): string {
    const h = Math.random();
    const s = 1;
    const l = Math.exp(-this.random(0, 3));
    const couleur = this.hslToRgb(h, s, l);
    return couleur;
  }

  randomFlashyColor(): string {
    const h = Math.random();
    const s = 1;
    const l = 0.5;
    const couleur = this.hslToRgb(h, s, l);
    return couleur;
  }

  random(from: number, to: number): number {
    return Math.random() * (to - from) + from;
  }

  randomPct(from: number, to: number): number {
    return this.random(from, to) / 100;
  }

  hslToRgb(h: number, s: number, l: number) {
    let r: number;
    let g: number;
    let b: number;

    if (s === 0) {
      r = g = b = l;
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = this.hue2rgb(p, q, h + 1 / 3);
      g = this.hue2rgb(p, q, h);
      b = this.hue2rgb(p, q, h - 1 / 3);
    }

    return `rgb(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)})`;
  }

  hue2rgb(p: number, q: number, t: number) {
    if (t < 0) { t += 1; }
    if (t > 1) { t -= 1; }
    if (t < 1 / 6) { return p + (q - p) * 6 * t; }
    if (t < 1 / 2) { return q; }
    if (t < 2 / 3) { return p + (q - p) * (2 / 3 - t) * 6; }
    return p;
  }
}
