<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <radialGradient [attr.id]="getId('gradientAbdomen')" cx="0.5" cy="0.5" r="0.5" fx="0.25" fy="0.25">
      <stop offset="0%" [attr.stop-color]="i.abdomen.reflet" />
      <stop offset="100%" stop-color="rgba(255,255,255,0)" />
    </radialGradient>
    <radialGradient [attr.id]="getId('gradientAbdomen2')" cx="0.7" cy="0.7" fx="0.8" fy="0.8" r="0.3">
      <stop offset="0%" [attr.stop-color]="i.abdomen.reflet" />
      <stop offset="100%" stop-color="rgba(255,255,255,0)" />
    </radialGradient>
    <radialGradient [attr.id]="getId('gradientThorax')" cx="0.5" cy="0.5" r="0.5" fx="0.25" fy="0.25">
      <stop offset="0%" [attr.stop-color]="i.thorax.reflet" />
      <stop offset="100%" stop-color="rgba(255,255,255,0)" />
    </radialGradient>
    <radialGradient [attr.id]="getId('gradientThorax2')" cx="0.7" cy="0.6" fx="0.8" fy="0.7" r="0.3">
      <stop offset="0%" [attr.stop-color]="i.thorax.reflet" />
      <stop offset="100%" stop-color="rgba(255,255,255,0)" />
    </radialGradient>
    <radialGradient [attr.id]="getId('gradientTete')" cx="0.5" cy="0.5" r="0.5" fx="0.25" fy="0.25">
      <stop offset="0%" [attr.stop-color]="i.tete.reflet" />
      <stop offset="100%" stop-color="rgba(255,255,255,0)" />
    </radialGradient>
    <radialGradient [attr.id]="getId('gradientTete2')" cx="0.7" cy="0.5" fx="0.8" fy="0.6" r="0.3">
      <stop offset="0%" [attr.stop-color]="i.tete.reflet" />
      <stop offset="100%" stop-color="rgba(255,255,255,0)" />
    </radialGradient>
    <radialGradient [attr.id]="getId('gradientYeux')" cx="0.5" cy="0.5" r="0.5" fx="0.25" fy="0.25">
      <stop offset="0%" stop-color="rgba(255,255,255,0.3)" />
      <stop offset="100%" stop-color="rgba(255,255,255,0)" />
    </radialGradient>
    <radialGradient [attr.id]="getId('gradientYeux2')" cx="0.7" cy="0.5" fx="0.8" fy="0.6" r="0.3">
      <stop offset="0%" stop-color="rgba(255,255,255,0.3)" />
      <stop offset="100%" stop-color="rgba(255,255,255,0)" />
    </radialGradient>
  </defs>

  <g transform="matrix(1 0 0 -1 50 50)">
    <!-- BACKGROUND
    <rect x="-50" y="-50" width="100" height="100" fill="rgb(251,248,232)" />
    -->

    <!-- QUADRILLAGE
    <g>
      <line x1="-50" y1="40" x2="50" y2="40" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="-50" y1="30" x2="50" y2="30" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="-50" y1="20" x2="50" y2="20" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="-50" y1="10" x2="50" y2="10" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="-50" y1="0" x2="50" y2="0" stroke="rgba(0,0,0,0.1)" />
      <line x1="-50" y1="-10" x2="50" y2="-10" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="-50" y1="-20" x2="50" y2="-20" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="-50" y1="-30" x2="50" y2="-30" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="-50" y1="-40" x2="50" y2="-40" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />

      <line x1="-40" y1="50" x2="-40" y2="-50" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="-30" y1="50" x2="-30" y2="-50" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="-20" y1="50" x2="-20" y2="-50" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="-10" y1="50" x2="-10" y2="-50" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="0" y1="50" x2="0" y2="-50" stroke="rgba(0,0,0,0.1)" />
      <line x1="10" y1="50" x2="10" y2="-50" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="20" y1="50" x2="20" y2="-50" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="30" y1="50" x2="30" y2="-50" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
      <line x1="40" y1="50" x2="40" y2="-50" stroke="rgba(0,0,0,0.1)" stroke-width="0.25" />
    </g>
    -->

    <!-- PROPORTIONS
    <g>
      <circle cx="0" [attr.cy]="haut" r="0.3" fill="red" />
      <circle cx="0" [attr.cy]="cou" r="0.3" fill="red" />
      <circle cx="0" [attr.cy]="hanche" r="0.3" fill="red" />
      <circle cx="0" [attr.cy]="bas" r="0.3" fill="red" />
    </g>
    -->

    <!-- PATTES -->
    <g>
      <path *ngFor="let patte of i.thorax.pattes.paths" [attr.d]="patte" [attr.stroke-width]="i.thorax.pattes.epaisseur"
        stroke="black" stroke-linecap="round" fill="transparent" />
    </g>

    <!-- TETE -->
    <g>
      <line *ngIf="i.tete.trompe" x1="0" [attr.y1]="haut" x2="0" [attr.y2]="i.tete.trompe.y"
        [attr.stroke-width]="i.tete.trompe.epaisseur" stroke="black" />
      <g *ngIf="i.tete.broyeurs">
        <path [attr.d]="i.tete.broyeurs.pathGauche" [attr.fill]="i.tete.couleur" [attr.stroke-width]="strokeWidth"
          stroke="black" />
        <path [attr.d]="i.tete.broyeurs.pathDroite" [attr.fill]="i.tete.couleur" [attr.stroke-width]="strokeWidth"
          stroke="black" />
      </g>
      <g *ngIf="i.tete.mandibules">
        <path [attr.d]="i.tete.mandibules.pathGauche" [attr.stroke-width]="i.tete.mandibules.epaisseur" stroke="black"
          fill="transparent" stroke-linecap="round" />
        <path [attr.d]="i.tete.mandibules.pathDroite" [attr.stroke-width]="i.tete.mandibules.epaisseur" stroke="black"
          fill="transparent" stroke-linecap="round" />
      </g>
      <g>
        <path [attr.d]="i.tete.antennes.pathGauche" [attr.stroke-width]="i.tete.antennes.epaisseur" stroke="black"
          stroke-linecap="round" fill="transparent" />
        <path [attr.d]="i.tete.antennes.pathDroite" [attr.stroke-width]="i.tete.antennes.epaisseur" stroke="black"
          stroke-linecap="round" fill="transparent" />
      </g>
      <path [attr.d]="i.tete.path" [attr.fill]="i.tete.couleur" [attr.stroke-width]="strokeWidth" stroke="black"
        stroke-linecap="round" />
      <path [attr.d]="i.tete.path" [attr.fill]="getUrl('gradientTete')" />
      <path [attr.d]="i.tete.path" [attr.fill]="getUrl('gradientTete2')" />
      <g *ngIf="i.tete.ocelles.yAvant">
        <circle cx="0" [attr.cy]="i.tete.ocelles.yAvant" [attr.r]="i.tete.ocelles.r"
          [attr.fill]="i.tete.ocelles.couleur" />
        <circle cx="0" [attr.cy]="i.tete.ocelles.yAvant" [attr.r]="i.tete.ocelles.r" [attr.fill]="getUrl('gradientYeux')" />
        <circle cx="0" [attr.cy]="i.tete.ocelles.yAvant" [attr.r]="i.tete.ocelles.r" [attr.fill]="getUrl('gradientYeux2')" />
      </g>
      <g>
        <circle [attr.cx]="i.tete.ocelles.xArriere" [attr.cy]="i.tete.ocelles.yArriere" [attr.r]="i.tete.ocelles.r"
          [attr.fill]="i.tete.ocelles.couleur" />
        <circle [attr.cx]="i.tete.ocelles.xArriere" [attr.cy]="i.tete.ocelles.yArriere" [attr.r]="i.tete.ocelles.r"
          [attr.fill]="getUrl('gradientYeux')" />
        <circle [attr.cx]="i.tete.ocelles.xArriere" [attr.cy]="i.tete.ocelles.yArriere" [attr.r]="i.tete.ocelles.r"
          [attr.fill]="getUrl('gradientYeux2')" />
        <circle [attr.cx]="-i.tete.ocelles.xArriere" [attr.cy]="i.tete.ocelles.yArriere" [attr.r]="i.tete.ocelles.r"
          [attr.fill]="i.tete.ocelles.couleur" />
        <circle [attr.cx]="-i.tete.ocelles.xArriere" [attr.cy]="i.tete.ocelles.yArriere" [attr.r]="i.tete.ocelles.r"
          [attr.fill]="getUrl('gradientYeux')" />
        <circle [attr.cx]="-i.tete.ocelles.xArriere" [attr.cy]="i.tete.ocelles.yArriere" [attr.r]="i.tete.ocelles.r"
          [attr.fill]="getUrl('gradientYeux2')" />
      </g>
      <g>
        <ellipse [attr.cx]="i.tete.yeux.cx" [attr.cy]="i.tete.yeux.cy" [attr.rx]="i.tete.yeux.rx"
          [attr.ry]="i.tete.yeux.ry" [attr.fill]="i.tete.yeux.couleur" />
        <ellipse [attr.cx]="i.tete.yeux.cx" [attr.cy]="i.tete.yeux.cy" [attr.rx]="i.tete.yeux.rx"
          [attr.ry]="i.tete.yeux.ry" [attr.fill]="getUrl('gradientYeux')" />
        <ellipse [attr.cx]="i.tete.yeux.cx" [attr.cy]="i.tete.yeux.cy" [attr.rx]="i.tete.yeux.rx"
          [attr.ry]="i.tete.yeux.ry" [attr.fill]="getUrl('gradientYeux2')" />
        <ellipse [attr.cx]="-i.tete.yeux.cx" [attr.cy]="i.tete.yeux.cy" [attr.rx]="i.tete.yeux.rx"
          [attr.ry]="i.tete.yeux.ry" [attr.fill]="i.tete.yeux.couleur" />
        <ellipse [attr.cx]="-i.tete.yeux.cx" [attr.cy]="i.tete.yeux.cy" [attr.rx]="i.tete.yeux.rx"
          [attr.ry]="i.tete.yeux.ry" [attr.fill]="getUrl('gradientYeux')" />
        <ellipse [attr.cx]="-i.tete.yeux.cx" [attr.cy]="i.tete.yeux.cy" [attr.rx]="i.tete.yeux.rx"
          [attr.ry]="i.tete.yeux.ry" [attr.fill]="getUrl('gradientYeux2')" />
      </g>
    </g>

    <!-- ABDOMEN -->
    <g>
      <path *ngIf="i.abdomen.dard" [attr.d]="i.abdomen.dard.path" fill="black" />
      <path [attr.d]="i.abdomen.path" [attr.fill]="i.abdomen.couleur" [attr.stroke-width]="strokeWidth" stroke="black"
        stroke-linecap="round" />

      <clipPath [attr.id]="getId('clipAbdomen')">
        <path [attr.d]="i.abdomen.path" />
      </clipPath>
      <g *ngIf="i.abdomen.motif" [attr.clip-path]="getUrl('clipAbdomen')">
        <path class="toto" *ngFor="let path of i.abdomen.motif.paths" [attr.d]="path"
          [attr.fill]="i.abdomen.motif.couleur" />
      </g>

      <path [attr.d]="i.abdomen.path" [attr.fill]="getUrl('gradientAbdomen')" />
      <path [attr.d]="i.abdomen.path" [attr.fill]="getUrl('gradientAbdomen2')" />
      <line x1="0" *ngIf="i.abdomen.carapace" [attr.y1]="hanche" x2="0" [attr.y2]="bas"
        [attr.stroke-width]="strokeWidth" stroke="black" />
    </g>

    <!-- THORAX -->
    <g>
      <path [attr.d]="i.thorax.path" [attr.fill]="i.thorax.couleur" [attr.stroke-width]="strokeWidth" stroke="black"
        stroke-linecap="round" />
      <path [attr.d]="i.thorax.path" [attr.fill]="getUrl('gradientThorax')" />
      <path [attr.d]="i.thorax.path" [attr.fill]="getUrl('gradientThorax2')" />
    </g>

    <!-- AILES -->
    <g *ngIf="i.thorax.ailes">
      <path [attr.d]="i.thorax.ailes.paths" fill="rgba(255,255,255,0.5)" stroke-width="0.1" stroke="black" />
    </g>
  </g>
</svg>
